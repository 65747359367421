import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { ProductPreview } from "../utils/product";

// TODO: Pagination component can be made into a component
// TODO: Handle no classes. throws an error if no classes
class Classes extends React.Component {
  render() {
    const {
      data: {
        allShopifyProduct: { nodes: products },
      },
      pageContext,
    } = this.props;
    const { currentPage, numberOfPages } = pageContext;

    return (
      <Layout>
        <section className="container">
          <nav
            className="level pagination"
            role="navigation"
            aria-label="pagination"
          >
            <div className="level-left">
              <div className="level-item">
                <p className="title is-3">Classes</p>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                {currentPage !== 1 && (
                  <Link
                    className="pagination-previous"
                    disabled={currentPage === 1}
                    to={`/classes/${currentPage <= 2 ? "" : currentPage - 1}`}
                  >
                    Previous
                  </Link>
                )}
                {currentPage !== numberOfPages && (
                  <Link
                    className="pagination-next"
                    disabled={currentPage === numberOfPages}
                    to={`/classes/${
                      currentPage === numberOfPages ? "" : currentPage + 1
                    }`}
                  >
                    Next page
                  </Link>
                )}
              </div>
            </div>
          </nav>
          <div className="tile is-ancestor">
            {products.slice(0, 3).map((p) => (
              <ProductPreview
                key={`product-${p.handle}`}
                product={p}
                slug="/classes"
                redirectToDetail={true}
              />
            ))}
          </div>
          <div className="tile is-ancestor">
            {products.slice(3, 6).map((p) => (
              <ProductPreview
                key={`product-${p.handle}`}
                product={p}
                slug="/classes"
                redirectToDetail={true}
              />
            ))}
          </div>
          <div className="tile is-ancestor">
            {products.slice(6, 9).map((p) => (
              <ProductPreview
                key={`product-${p.handle}`}
                product={p}
                slug="/classes"
                redirectToDetail={true}
              />
            ))}
          </div>
          <div className="tile is-ancestor">
            {products.slice(9, 12).map((p) => (
              <ProductPreview
                key={`product-${p.handle}`}
                product={p}
                slug="/classes"
                redirectToDetail={true}
              />
            ))}
          </div>
          <div className="tile is-ancestor">
            {products.slice(12, 15).map((p) => (
              <ProductPreview
                key={`product-${p.handle}`}
                product={p}
                slug="/classes"
                redirectToDetail={true}
              />
            ))}
          </div>
          <nav
            className="pagination mb-3"
            role="navigation"
            aria-label="pagination"
          >
            <Link
              className="pagination-previous"
              disabled={currentPage === 1}
              to={`/classes/${currentPage <= 2 ? "" : currentPage - 1}`}
            >
              Previous
            </Link>
            <Link
              className="pagination-next"
              disabled={currentPage === numberOfPages}
              to={`/classes/${
                currentPage === numberOfPages ? "" : currentPage + 1
              }`}
            >
              Next page
            </Link>
            <ul className="pagination-list">
              {[...Array(numberOfPages).keys()].map((i) => (
                <li key={`paging-li-${i}`}>
                  <Link
                    className={`pagination-link ${
                      currentPage === i + 1 ? "is-current" : ""
                    }`}
                    aria-label={
                      i + 1 === currentPage
                        ? `Page ${i + 1}`
                        : `Goto page ${i + 1}`
                    }
                    to={i > 0 ? `/classes/${i + 1}` : "/classes"}
                    aria-current={i + 1 === currentPage ? "page" : ""}
                  >
                    {i + 1}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </section>
      </Layout>
    );
  }
}

export const classesQuery = graphql`
  query classesQuery($skip: Int!, $limit: Int!) {
    allShopifyProduct(
      filter: { productType: { eq: "Classes" } }
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        handle
        title
        productType
        variants {
          shopifyId
          price
          title
        }
        media {
          preview {
            image {
              gatsbyImageData(layout: CONSTRAINED, width: 512)
            }
          }
        }
      }
    }
  }
`;

export default Classes;
